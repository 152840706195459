/* TODO Define this in one place onyl (already defined in layoutDefault.css) */

:root {
    --main-menu-height: 64px;
    --sidebar-width: 200px;
}

header.ant-layout-header.main-header {
    @apply fixed z-10 w-full p-0;
    height: var(--main-menu-height);
}

header.ant-layout-header.main-header .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

header.ant-layout-header.main-header .content .side-left {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
}

header.ant-layout-header.main-header .content .side-right {
    display: flex;
    justify-content: flex-end;
}

/* position the breadcrumbs */
header.ant-layout-header.main-header .breadcrumbs {
    margin-left: 24px;
}
