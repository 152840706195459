:root {
    --main-menu-height: 64px;
    --sidebar-width: 200px;
}

/* Main Menu setup */
.default-layout header.ant-layout-header.main-header .content {
    /* leave space for the left sidebar */
    margin-left: calc(var(--sidebar-width) + 24px);
}

.default-layout .layout-content {
    margin-left: 200px;
}

.default-layout main.ant-layout-content {
    /* main menu height + section top padding (16px) */
    margin-top: calc(var(--main-menu-height) + 16px) !important;
}
