/* TODO Move this to a more general file */

.view-header {
    display: flex;
}

/* .view-body .left-side {
} */

.view-body .title-container {
    display: flex;
    align-items: center;
}
.view-body .title-container .nav-icon {
    width: 0;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.view-body .title-container .nav-icon .icon-container {
    position: relative;
    width: 32px;
    margin-left: -28px;
}

.view-body .title-container .nav-icon .anticon {
    font-size: 18px;
}

.view-body .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}
