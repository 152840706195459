.main-content {
    margin: 16px 16px;
    overflow: initial;
    filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
}

.main-content .inner-container {
    padding: 24px 48px;
    text-align: left;
    /* background-color: white; */
    border-radius: 4px;
}
