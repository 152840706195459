/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* Colors */
:root {
    --color-primary: #4096ff;
    --color-dark: #001529;
}

body {
    padding: 0;
    margin: 0;

    /*
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    */
}

/* UTILITIES */

/* COLORS */

.text-primary {
    color: var(--color-primary);
}

.text-dark {
    color: var(--color-dark);
}

/* Transitions */

.transition-fade-opacity {
    transition: opacity linear 0.3s;
}
